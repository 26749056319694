$(function(){
	var current_page = $("header").data("current-page")
	var window_h = $(window).height()
	var home_banner_delta, home_banner_start, home_banner_step
	
	// home-banner 動畫
	if(current_page == "home#index"){
		// 判斷banner圖片載入
		var banner_images_loaded = false
		var imgdefereds=[];
		$("#home-banner img").each(function(){
			var dfd=$.Deferred();
			$(this).bind('load',function(){
				dfd.resolve();
			}).bind('error',function(){
				console.log('圖片載入錯誤')
			})
			if(this.complete){
				setTimeout(function(){
					dfd.resolve();
				},200);
			}
			imgdefereds.push(dfd);
		})
		// function set_banner_animation_params(){
		// 	// 讓動畫保持垂直中央的位置微調
		// 	home_banner_delta = window_h/2-$("#home-banner .banner-animation img:first-child").outerHeight()/2+25
		// 	// 動畫開始位置
		// 	home_banner_start = ($("#home-banner .banner-animation").position().top-home_banner_delta)
		// 	// 動畫每個滾動量
		// 	home_banner_step = ($("#home-banner .banner-animation").outerHeight()+$("#home-banner .banner-image").outerHeight()/5)/8
		// }
		// $.when.apply(null,imgdefereds).done(function(){
		// 	banner_images_loaded = true
		// 	set_banner_animation_params()
		// });

		// 螢幕寬度調整後，重新計算動畫
		// $(window).resize(function() {
		// 	set_banner_animation_params()
		// })

		var home_banner_img_index = 1
		
		$(window).on('scroll',function(){
			if(banner_images_loaded){
				scroll = $(this).scrollTop()
				if(scroll >= home_banner_start && scroll <= home_banner_start + home_banner_step*8){
					// 盤子動畫開始位置
					$(".banner-animation-group").addClass("active")
					$("#home-banner .banner-image").removeClass("active")
					$(".banner-animation-group").css('top',home_banner_delta)
					home_banner_img_index = Math.floor((scroll-home_banner_start)/home_banner_step)+1
					set_home_banner_animation()
				}else if(scroll > home_banner_start + home_banner_step*8){
					// 盤子動畫結束，盤子fade out，大圖fade in
					$(".banner-animation-group").removeClass("active")
					$("#home-banner .banner-image").addClass("active")
					$(".banner-animation-group").css('top',home_banner_step*8)

					pot_opacity = (50-(scroll - (home_banner_start + home_banner_step*8)))/50
					if(pot_opacity>1){
						pot_opacity = 1
					}else if(pot_opacity <0){
						pot_opacity = 0
					}
					$(".banner-animation-group .banner-img-group").css("opacity",pot_opacity)
					$("#home-banner .banner-image .red-cover").css("opacity",pot_opacity)
				}else{
					$(".banner-animation-group").removeClass("active")
					$("#home-banner .banner-image").addClass("active")
					$(".banner-animation-group").css('top',0)
				}
			}
		})
		set_home_banner_animation()
		function set_home_banner_animation(){
			if(home_banner_img_index >= 9){
				home_banner_img_index = 9
			}else if(home_banner_img_index <= 1){
				home_banner_img_index = 1
			}
			$(".banner-animation-group .banner-img-group").css("opacity",1)
			$("#home-banner .banner-image .red-cover").css("opacity",1)
			$(`.banner-animation .banner-img-group img`).removeClass("d-block")
			$(`.banner-animation .banner-img-group img:nth-child(${home_banner_img_index})`).addClass("d-block")
		}
	}

	// 加盟方案數字
	if(current_page == "home#franchise_plan"){
		$(window).on('scroll',function(){
			scroll = $(this).scrollTop()
			if(scroll >= $("#franchise-number").scrollTop()+300){
				$("#franchise-number").addClass("active")
			}
		})
	}
	
	// 以下改成全程顯示
	// 顯示header
	// var current_scroll = 0

	// $(window).on('scroll',function(){
	// 	if($(window).width()>992){
	// 		if(fixed_header){
	// 			$("header").addClass("active")
	// 		}else{
	// 			scroll = $(this).scrollTop()
	// 			if(scroll < current_scroll){
	// 				$("header").addClass("active")
	// 			}else{
	// 				$("header").removeClass("active")
	// 			}
	// 			current_scroll = scroll
	// 		}
	// 	}
	// })

	// 固定header顯示按鈕
	// var fixed_header = false
	// $("#header-btn").on('click',function(){
	// 	fixed_header = !fixed_header
	// 	if(fixed_header){
	// 		$("header").addClass("active")
	// 	}else{
	// 		$("header").removeClass("active")
	// 	}
	// })

	// 桌機版預設展開Header / 手機版預設隱藏
	set_header_active()
	$(window).resize(function() {
		set_header_active()
	})
	function set_header_active(){
		if($(window).width()>991){
			$("header").addClass("active")
		}else{
			$("header").removeClass("active")
		}
	}

	// Mobile Header
	$("#mobile-header-home-btn").on('click',function(){
		if($(window).width()<=991){
			$("header").toggleClass("active")
		}
	})
	$("header .mobile-item").on('click',function(){
		if($(window).width()<=991){
			$("header").removeClass("active")
		}
	})

	// header下拉選單
	$("header .item").on('click',function(){
		$(this).toggleClass('active')
	})

	// active button
	$(".active_btn").on('click',function(){
		if($(this).data("active-target") == undefined){
			$(this).toggleClass("active")
		}else{
			$($(this).data("active-target")).toggleClass("active")
		}
	})

	// footer active button
	// $(".footer_active_btn").on('click',function(){
	// 	$($(this).data("active-target")).toggleClass("active")
	// 	$($(this).data("active-target")).find("input").attr("disabled",false)
	// 	$($(this).data("active-target")).find("button").attr("disabled",false)
	// 	$($(this).data("active-target")).find("textarea").attr("disabled",false)
	// })

	// Home Products Slick
	$("#home-products-slick").slick({
		infinite: false,
		slidesToShow: 4,
		slidesToScroll: 2,
		responsive: [
		{
			breakpoint: 991,
			settings: {
				slidesToShow: 2,
			}
		}
		],
		prevArrow: '<div class="slick-prev-red"></div>',
		nextArrow: '<div class="slick-next-red"></div>'
	});

	$("#home-products2-slick").slick({
		infinite: true,
		slidesToShow: 2,
		slidesToScroll: 1,
		responsive: [
		{
			breakpoint: 991,
			settings: {
				slidesToShow: 2,
			}
		}
		],
		prevArrow: '<div class="slick-prev-white"></div>',
		nextArrow: '<div class="slick-next-white"></div>'
	});

	$(".product-slick").slick({
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 2,
		responsive: [
		{
			breakpoint: 991,
			settings: {
				slidesToShow: 2,
			}
		}
		],
		prevArrow: '<div class="slick-prev-white"></div>',
		nextArrow: '<div class="slick-next-white"></div>'
	});

	// 加盟見證
	$("#franchise-witness-slick").slick({
		infinite: true,
		slidesToShow: 2,
		slidesToScroll: 2,
		responsive: [
		{
			breakpoint: 991,
			settings: {
				slidesToShow: 2,
			}
		}
		],
		prevArrow: '<div class="slick-prev-white"></div>',
		nextArrow: '<div class="slick-next-white"></div>'
	});

	// to-top
	$(".to-top").on('click',function(){
		$(window).scrollTop(0)
	})

	// to-bottom
	$(".to-bottom").on('click',function(){
		$(window).scrollTop(999999)
	})

	// initialize-scroll-target
	if($(`${$("header").data("scroll-target")}`).length > 0){
		$(window).scrollTop($(`${$("header").data("scroll-target")}`).position().top)
	}

	// design select
	$(".design-select").each(function(){
		var current_select = $(this)
		var select_selected = $(`<div class="select-selected"><div></div><span>▼</span></div>`).appendTo(current_select)
		
		var select_items = $(`<div class="select-items"></div>`).appendTo(current_select)
		select_selected.on('click',function(){
			$(".design-select.select-active").removeClass("select-active")
			current_select.toggleClass("select-active")
		})
		current_select.find("option").each(function(i){
			var option_val = $(this).val()
			var select_item
			if(i == 0){
				select_selected.find('div').html($(this).text())
				select_item = $(`<div>全部</div>`).appendTo(select_items)
			}else{
				select_item = $(`<div>${$(this).text()}</div>`).appendTo(select_items)
			}
			select_item.on('click',function(){
				current_select.removeClass("select-active")
				current_select.find('select').val(option_val).change();
			})
		})
		current_select.find("select").on('change',function(){
			select_selected.find('div').html($(this).find("option:selected").text())
		})
	})

	// 關閉design select
	$("body").on('click',function(e){
		if(!$(e.target.offsetParent).hasClass("design-select")){
			$(".design-select").removeClass("select-active")
		}
	})

	var geolocationForbidden = false

	if(current_page == "stores#index"){
		getLocation()
	}

	// 取得鄰近店家
	$("#near-btn").on('click',function(){
		$("#store-distance-loading").show()
		$("#stores-index .store").each(function(){
			$(this).appendTo($(".store-group-hide"))
		})
		getLocation()
	})

	//取得經緯度
	function getLocation() {
		if (navigator.geolocation) {
			// 取得位置->計算分店距離
			// 未取得位置->直接顯示縣市
			navigator.geolocation.getCurrentPosition(calcDistance,geolocationError);
		}else{
			geolocationError()
		}
	}

	// 計算store距離&排序 
	function calcDistance(position){
		geolocationForbidden = false
		stores = []
		c_lat = position.coords.latitude*3.1415926/180
		c_lon = position.coords.longitude*3.1415926/180
		$("#stores-index .store").each(function(){
			s_lat = parseFloat($(this).data("lat"))*3.1415926/180
			s_lon = parseFloat($(this).data("lon"))*3.1415926/180
			if(isNaN(s_lat) || isNaN(s_lon)){
				distance = 99999999
				stores.push({dom_id: $(this).prop("id"), distance: distance})
				$(this).find(".distance").remove()
			}else{
				distance = 6372*Math.acos(Math.cos(s_lat)*Math.cos(c_lat)*Math.cos(s_lon-c_lon)+Math.sin(s_lat)*Math.sin(c_lat))
				stores.push({dom_id: $(this).prop("id"), distance: distance})
				$(this).find(".distance").html(`距離｜${Math.round(distance*10)/10}km`)
			}
		})
		stores.sort(function(a, b){return a["distance"]-b["distance"]});
		$.each(stores,function(i,store){
			if(i <= 1){
				$(`#${store["dom_id"]}`).appendTo($(".store-group"))
			}else{
				$(`#${store["dom_id"]}`).appendTo($(".store-group-hide"))
			}
		})
		$("#near-btn").addClass("active")
		$("#store-distance-loading").hide()
	}

	// 未取得位置動作
	function geolocationError(){
		$("#store-distance-loading").hide()
		if(geolocationForbidden){
			new Noty({text: "無法取得您的位置，請在設定選項中允許取得位置資訊存取權。", type: 'error'}).show();
		}
		geolocationForbidden = true
		showStoreList()
	}

	// 顯示分店List
	function showStoreList(){
		$("#near-btn").removeClass("active")
		county_selected = $("#stores-county-select select").val()
		tag_selected = $("#select-tag-group .tag").map(function(){
			return $(this).data("value")
		}).get()
		if(county_selected == ""){
			// 全部縣市
			$("#stores-index .store").each(function(){
				var store = $(this)
				if(tag_selected.length > 0){
					// 多選Tag
					$.each(tag_selected,function(i,tag){
						if(store.data("tags").indexOf(tag)>=0){
							store.appendTo(".store-group")
							return false;
						}else{
							store.appendTo(".store-group-hide")
						}
					})
				}else{
					// 全部Tag
					store.appendTo(".store-group")
				}
			})
		}else{
			// 單一縣市
			$("#stores-index .store").each(function(){
				var store = $(this)
				if(county_selected == store.data("county")){
					if(tag_selected.length > 0){
						// 多選Tag
						$.each(tag_selected,function(i,tag){
							if(store.data("tags").indexOf(tag)>=0){
								store.appendTo(".store-group")
								return false;
							}else{
								store.appendTo(".store-group-hide")
							}
						})
					}else{
						// 全部Tag
						store.appendTo(".store-group")
					}
				}else{
					store.appendTo(".store-group-hide")
				}
			})
		}
		if($("#stores-index .store-group .store").length <= 0){
			$("#store-nil").show()
		}else{
			$("#store-nil").hide()
		}
	}

	$("#stores-county-select select").on('change',function(){
		showStoreList()
	})

	// Store Tag 選取
	$("#stores-tag-select select").on('change',function(){
		value = $(this).val()
		if(value == ""){
			$(`#select-tag-group`).html("")
		}else{
			if($(`#select-tag-group [data-value='${value}']`).length > 0){
				$(`#select-tag-group [data-value='${value}']`).remove()
			}else{
				$("#select-tag-group").append(`<div class="tag" data-value="${value}"><img src="/images/cross.svg">${value}</div>`)
			}
		}
		showStoreList()
	})
	$(document).on("click","#select-tag-group .tag",function(){
		$(this).remove()
		// console.log("remove")
		showStoreList()
	})

	// QRcode
	$(".qrcode").each(function(){
		var qrcode_text = $(this).data("text")
		$(this).qrcode({
			text: qrcode_text,
			width: 200,
			height: 200,
			background: '#F2F2F0',
			foreground: '#DD4C42'
		});
	})


})